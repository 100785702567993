
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";
import FileUploader from "@/components/form-components/FileUploader.vue";
import { mask } from "vue-the-mask";
import MultiplePhonePicker from "@/components/form-components/MultiplePhonePicker.vue";
import DatePicker from "@/components/form-components/DatePicker.vue";
import CreateUser from "@/modules/users/views/create.vue";
import LiveSearch from "@/components/form-components/LiveSearch.vue";

export default Vue.extend({
  name: "Edit",

  components: {
    LiveSearch,
    CreateUser,
    DatePicker,
    MultiplePhonePicker,
    FileUploader
  },

  directives: { mask },

  data: () => ({
    rules,
    model: {
      signature: {} as any,
      is_active: true as boolean,
      user_id: "",
      certificates: [] as any,
      user: {
        value: 2,
        text: "test"
      } as SelectComponentInterface,
      birthdate: "" as string
    } as any,
    disableSave: false as boolean,
    fullLoaded: false as boolean,
    errorMessages: {} as { [value: string]: Array<string> },
    selects: {
      genders: [
        { value: "f", text: "F" },
        { value: "m", text: "M" }
      ] as Array<SelectComponentInterface>,
      centers: [] as Array<SelectComponentInterface>,
      workPositions: [] as Array<SelectComponentInterface>,
      users: [] as Array<SelectComponentInterface>,
      subdivisions: [] as Array<SelectComponentInterface>,
      branches: [] as Array<SelectComponentInterface>,
      certificates: [] as Array<SelectComponentInterface>,
      certificateTypes: [
        {
          value: 1,
          text: "Legitimation"
        },
        {
          value: 2,
          text: "Certiifcate"
        }
      ] as Array<SelectComponentInterface>
    } as any,
    allowedCertificatesRoles: [
      "specialist",
      "expert",
      "specialist_ata",
      "specialist_aaij"
    ] as Array<string>,
    showCreateUser: false as any,
    user: null as any
  }),

  watch: {
    model: {
      deep: true,
      handler() {
        if (this.fullLoaded) {
          this.disableSave = false;
        }
      }
    },
    showCertificates() {
      if (this.showCertificates && !this.model.certificates.length) {
        this.model.certificates.push({});
      }
    }
  },

  computed: {
    maxDate(): any {
      return this.$moment().format("YYYY-MM-DD");
    },
    showCertificates() {
      const rolesCertificates = [] as Array<any>;

      for (const item of this.selects.workPositions) {
        if (this.allowedCertificatesRoles.includes(item.text)) {
          rolesCertificates.push(item.value);
        }
      }

      return this.model.work_positions?.some((item: any) =>
        rolesCertificates.includes(item)
      );
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const [
        item,
        workPositions,
        branches,
        users,
        certificates
      ] = await Promise.all([
        API.employees().getForEdit(Number(to.params.id)),
        API.workPositions().getList(),
        API.branches().getList(),
        API.users().getEmployeesList(),
        API.employees().getCertificatesList()
      ]);

      let subdivisions = [] as Array<any>;
      if (item.branch_id) {
        subdivisions = await API.subdivisions().getListByBranch(item.branch_id);
      }

      next(vm => {
        vm.model = item;

        if (item.user) {
          vm.user = { value: item.user.id, text: item.user.name };
        }

        if (vm.model.phones) {
          vm.model.phones = vm.model.phones.split(",");
        } else {
          vm.model.phones = [];
        }

        vm.selects.workPositions = workPositions;
        vm.selects.branches = branches.items;
        vm.selects.users = users;
        vm.certificates = certificates;
        vm.selects.subdivisions = subdivisions;

        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          const model = { ...this.model };

          if (!this.showCertificates) {
            model.certificates = [];
          }

          await this.$API
            .employees()
            .edit(Number(this.$route.params.id), model);
          await this.$router.push("/employees");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 5000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async getListByBranch(): Promise<void> {
      try {
        this.selects.subdivisions = await this.$API
          .subdivisions()
          .getListByBranch(this.model.branch_id);

        if (
          !this.selects.subdivisions.some(
            (item: any) => item.value === this.model.subdivision_id
          )
        ) {
          delete this.model.subdivision_id;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    removeCertificate(index: number): void {
      this.model.certificates.splice(index, 1);
    },
    addCertificate(): void {
      this.model.certificates.push({});
    },
    setUser(user: any): void {
      this.model.user_id = user.value;
      this.user = user;
    },
    async searchUser(searchValue: string): Promise<any> {
      try {
        return {
          items: await this.$API.users().liveSearchEmployees({ searchValue })
        };
      } catch (e) {
        await this.$store.dispatch("alert/showError");
      }
    },
    toggleCreateUser(): void {
      this.showCreateUser = !this.showCreateUser;
    }
  }
});
